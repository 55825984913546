.About{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
    height: 100vh;
}
.About__Title{
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    border-bottom: 1px solid black;
    text-align: center;
    padding: 5px;
    font-size: 2.5rem;
}
.About__Body{
    padding: 5px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    line-height: 2.6;
}
.About__Body img{
    height: 1em;
    width: auto;
    border: none;
}
.Link{
    text-decoration: none;
    color: black;
}
.About__Body__Button{
    font-size: 1.5rem;
    background-color: white;
    padding: 0.5rem;
    border: 2px solid black;
    border-radius: 5px;
}
.About__Body__Button:hover{
    box-shadow: -2px 3px 24px 0px rgba(26,28,109,0.61);
    -webkit-box-shadow: -2px 3px 24px 0px rgba(26,28,109,0.61);
    -moz-box-shadow: -2px 3px 24px 0px rgba(26,28,109,0.61);

}
.Download__Resume{
    display: flex;
}
.downloadBtn{
    font-size: 1.9rem !important;
}