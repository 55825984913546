@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.contact{
    display: flex;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.5rem;
}
.contact__Title{
    text-align: center;
    border-bottom: 1px solid black;
}
.contact__Text{
    padding-left: 1rem;
}
.contact__body{
    display: flex;
    justify-content: space-around;
}
.contact__Info{
    display: flex;
    flex-direction: column;
}
.contact__Form{
    display: flex;
    flex-direction: column;
}
input{
    margin: 1rem;
    font-size: 1.5rem;
    outline: none;
    padding: 5px;
    background: none;
    border: 1px solid black;
}
.contact__Form{
    align-self: center;
}
.contact__Info{
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 150px;
    position: relative;
}
.contact__Info__IconHolder{
    display: flex ;
    justify-content: space-around;
    font-size: 5rem;
    max-width: 100%;

}
.contact__Info__IconHolder a{
    color: black;
}
.IconHolder__Icon{
    flex-basis: 100px;
	flex-grow: 0;
	flex-shrink: 0;
}
.Icon{
    font-size: 3.5rem !important;
}
.Icon:hover{
    font-size: 4.5rem !important;
    cursor: pointer;
    color: blue;
    transition: 0.2s ease-out;
}