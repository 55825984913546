/* .headerBody{
    padding-top: 1rem;
    border-bottom: 1px solid black;
    font-family: 'DM Sans', sans-serif;
}
.headerBody__container{
    display: flex;
    justify-content: space-between;
}
.headerBody__Chat{
    max-width: 25rem;
}
.headerBody__Welcome{
    padding-right: 2rem;
}
.headerBody__SelfIntro{
    max-width: 25rem;
    text-align: center;
    padding-top: 2rem;
    font-size: 1.5rem;
    position:absolute;
    padding-left: 35rem;
}  */
@import url('http://fonts.cdnfonts.com/css/gosha-sans?styles=77958');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.headerBody{
    position: relative;
    padding-top: 1rem;
    border-bottom: 1px solid black;
    font-family: 'DM Sans', sans-serif;
    height: 90vh;
    display: flex;
    flex-direction: column;
}
.headerBody__container{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
}
.headerBody__container1{
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.headerBody__container2{
    z-index: 9;
    justify-content: center;
    text-align: center;
    font-family: 'Gosha Sans', sans-serif;
    font-size: 2.5rem;
}
hr{
    width:75%;
    margin-left:15% !important;
    margin-right:15% !important;
    border-top: 3px double #000000;
}
@media screen and (max-width: 600px) {
    .headerBody__Snow{
        visibility: hidden;
    }
    .headerBody__container1{
        position: absolute;
        width: 100rem;
        z-index: 9;
    }
    .headerBody__container2{
        margin-top: 25rem;
    }
}
.headerBody__Chat{
    max-width: 30rem;
    flex-basis: 100%;
}
.headerBody__Welcome{
    padding-right: 2rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.headerBody__SelfIntro{
    font-family: 'Gosha Sans', sans-serif;
    margin-left: -15%;
    max-width: 25rem;   
    text-align: center;
    padding-top: 2rem;
    font-size: 2.5rem;
    flex-basis: 100%;
    font-weight: 200;
}
span {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;

}
@keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
@-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
}
/* Waves Here
@media only screen and (max-width: 700px) {
    .headerBody__container{
        overflow: hidden;
        position: relative;
        height: 39%;
    }
    .wave{
        padding-top: 12rem;}
}
@media only screen and (max-width: 2000px) {
    .headerBody__container{
        overflow: hidden;
        position: relative;
        height: 100%;
    }
    .wave{
        padding-top: 55rem;
    }
}

.wave{
    padding-top: 55rem;
    margin-left: -15%;
    width: 2000px;
    height: 2025px;
    background-color: rgb(122, 122, 230);
    opacity:0.6;
    position: absolute;
    border-radius: 50%; 
  }
  .wave.one{
    animation: wave 15s linear -1s infinite;
}
.wave.two{
    animation: wave 18s linear -1s infinite;
}
.wave.three{
    animation: wave 21s linear -1s infinite;
}
.wave.four{
    animation: wave 24s linear -1s infinite;
}
@keyframes wave{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
} */