.Projects{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'DM Sans', sans-serif;
}
.Projects__projectComp{
    display: flex;
    text-align: center;
    border-bottom: 1px solid black;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 2.5rem;
}
.Projects__projectContainer{
    display: flex;
    justify-content: center;
    border-bottom: 1px solid black;
}
.Projects__project{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-left: 1px solid black; 
    align-items: center;
    justify-content: center;
}
.Projects__project:first-child{
    border-right: none;
    border-left: none;
}
.Projects__projectTitle{
    text-align: center;
    flex-basis: 100%;
    font-size: 1.7rem;
}
.Projects__projectDesc{
    font-weight: 500;
    flex-basis: 100%;
    max-width: 400px;
}
img {
    width: 400px;
    border: 1px solid black;
    height: 250px;
    flex-basis: 100%;
}
img::after{
    margin-top: -1px;
}
.Projects__projectBtn{
    text-decoration: inherit;
    color: black;
    padding: 1rem;
    border: 1px solid black;
    box-shadow: 3px 3px ;
    flex-basis: 100%;
}
.Projects__projectBtn:hover{
    color: white;
    padding: 1rem;
    border: 1px solid black;
    background-color: black;
    transition: 0.2s ease-out;  
    animation-name: anime;
    animation-duration: 0.2s;
}
@keyframes anime{
    0%{box-shadow: 3px 3px;}
    50%{box-shadow: 1.5px 1.5px;}
    100%{box-shadow: 0px 0px;}
}