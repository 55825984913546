:root {
    --no-face-color: #222;
    --no-face-gradient: linear-gradient(to bottom, #222 40%, #222e 60%, #2229 80%, transparent 100%);
    --no-face-shadow: #888;
    --no-face-width: 175px;
    --no-face-height: 400px;
    --mark-color: #876f83;
    --mouse-color: #cfbab5;
    --mouse-shadow: #b9a6a2;
    --custom-filter: drop-shadow(2px 0 0 var(--mouse-shadow)) drop-shadow(0 2px 0 var(--mouse-shadow)) drop-shadow(-2px 0 0 var(--mouse-shadow)) drop-shadow(0 -2px 0 var(--mouse-shadow));
}
.container {
    margin-top: 1rem;
}  
.no-face {
    background: var(--no-face-gradient);
    width: var(--no-face-width);
    height: var(--no-face-height);
    border-radius: 45% 45% 0 0 / 20% 20% 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    filter: drop-shadow(0 0 2px #5f3399) drop-shadow(0 0 5px #391469) drop-shadow(0 0 15px #270850);
}
.no-face > .face {
    width: 125px;
    height: 175px;
    margin-top: 20px;
    border-radius: 50% 50% 44% 44% / 30% 30% 44% 44%;
    background: #fff;
}
.no-face > .face > .eyes-container {
    width: 110px;
    height: 110px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.eye-container {
    width: 45px;
    height: 90px;
    transform: translateY(20px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.right {
    transform: translateY(20px) scaleX(-1);
}
.mark {
    background: var(--mark-color);
  }
.mark-top {
    width: 10px;
    height: 22px;
    border-radius: 100% 100% 40% 40% / 200% 200% 25% 25%;
    transform: translateX(5px);
}
.eye {
    width: 30px;
    height: 11px;
    background: var(--no-face-color);
    border-radius: 50%;
}
.eyebag {
    width: 30px;
    height: 11px;
    background: var(--no-face-shadow);
    border-radius: 50%;
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}
.mark-bottom {
    width: 18px;
    height: 35px;
    border-radius: 50% 50% 100% 100% / 45% 45% 150% 150%;
}
.mouth-container {
    width: 100px;
    height: 40px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.mouth {
    width: 55px;
    height: 22px;
    background: var(--no-face-color);
    border-radius: 50%;
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}
.chin {
    width: 25px;
    height: 8px;
    background: var(--no-face-shadow);
    border-radius: 50%;
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}