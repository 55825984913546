.chatWindow{
    display: flex;
    flex-direction:column;
    margin-left: 1.5rem;
}
.chatWindow__message{
    background-color: lightgray;
    font-size: 1.5rem;
    word-wrap: break-word;
    font-weight: 500;
    width:fit-content;
    max-width: 50%;
    padding: 15px;
    border-radius:  1.25rem;
}