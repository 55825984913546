.header{
    height: 60px;
    font-size: 20px;
    display: flex;
    position: sticky;
    top:0;
    z-index: 100;
    border-bottom: 1px solid black;
    background-color: white;
    backdrop-filter: blur(10px);
}
.header__container{
    padding: 15px;
    display: flex;
    flex:1;
    justify-content: space-between;
    align-items: center;
}
.header__containerLeft a{
    padding-left: 15px;
    text-decoration: inherit;
    color: black;
    font-weight: 500;
}
.header__containerLeft a:hover{
    transition: 0.2s ease-out;
    letter-spacing: 1px;
    text-decoration: underline;
}
.header__containerRight{
    display:flex;
    padding-right: 35px;
}
.header__containerOptions a{
    text-decoration: inherit;
    color: black;
    margin: 0 5px;
    padding: 1rem;
}
.header__containerOptions a:hover{
    color: white;
    background-color: black;
    padding: 1rem;
}